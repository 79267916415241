import { httpGet, httpPut, httpPost, httpDel } from 'boot/axios';
const state = {
	lists: [],
	lazyLists: [],
	activeEmployee: null,
	searchResults: [],
	suggestResults: [],

	listPerEmployee: [],
	resetLazyLoad: false,
	listToBeDeleted: [],

	triggers: {
		displayBulkDelete: false,
	},
};

const getters = {
	GET_BRANCH_ASSIGNMENTS(state) {
		return state.lists;
	},

	GET_BRANCH_ASSIGNMENTS_LAZY(state) {
		return state.lazyLists;
	},

	GET_ACTIVE_EMPLOYEE(state) {
		return state.activeEmployee;
	},
	GET_SEARCH_RESULTS(state) {
		return state.searchResults;
	},

	GET_SUGGEST_RESULTS(state) {
		return state.suggestResults;
	},

	GET_BRANCH_ASSIGNMENTS_PER_EMPLOYEE(state) {
		return state.listPerEmployee;
	},

	GET_RESET_LAZY_LOAD(state) {
		return state.resetLazyLoad;
	},

	GET_LISTS_TO_BE_DELETED(state) {
		return state.listToBeDeleted;
	},

	GET_GLOBAL_TRIGGERS(state) {
		return state.triggers;
	},
};

const mutations = {
	SET_BRANCH_ASSIGNMENTS(state, payload) {
		state.lists = payload;
	},

	SET_BRANCH_ASSIGNMENTS_LAZY(state, payload) {
		state.lazyLists = payload;
	},

	SET_BRANCH_ASSIGNMENTS_PER_EMPLOYEE(state, payload) {
		state.listPerEmployee = payload;
	},
	SET_SEARCH_DATA(state, payload) {
		state.searchResults = payload;
	},

	SET_SUGGEST_DATA(state, payload) {
		state.suggestResults = payload;
	},

	INSERT_BRANCH_ASSIGNMENTS(state, payload) {
		/** Check if branch assignment exist */
		let exist = state.lists.filter((ba) => ba.employee_id === Number(payload.employee_id));
		!exist.length && state.lists.unshift(payload);
		!exist.length && state.lazyLists.unshift(payload);
	},

	MODIFY_BRANCH_ASSIGNMENTS(state, payload) {
		let objectIndex = state.listPerEmployee.findIndex((e) => e.id === payload.id);
		Object.keys(state.listPerEmployee[objectIndex]).forEach((key) => {
			typeof payload[key] !== 'undefined' && (state.listPerEmployee[objectIndex][key] = payload[key]);
		});

		objectIndex = state.lazyLists.findIndex((e) => e.id === payload.id);
		Object.keys(state.lazyLists[objectIndex]).forEach((key) => {
			payload[key] && (state.lazyLists[objectIndex][key] = payload[key]);
		});
	},

	INSERT_BRANCH_ASSIGNMENTS_PER_EMPLOYEE(state, payload) {
		/* Insert Branch Assignment into */
		state.listPerEmployee.unshift(payload);
	},

	REMOVE_BRANCH_ASSIGNMENTS_PER_EMPLOYEE(state, payload) {
		payload.forEach((id) => {
			let objectIndex = state.listPerEmployee.findIndex((e) => e.id === id);
			objectIndex !== -1 && state.listPerEmployee.splice(objectIndex, 1);

			let objectIndex2 = state.lazyLists.findIndex((e) => e.id === id);
			objectIndex2 !== -1 && state.lazyLists.splice(objectIndex2, 1);
		});
	},

	REMOVE_BRANCH_ASSIGNMENTS(state, payload) {
		payload.forEach((id) => {
			let objectIndex = state.lists.findIndex((e) => e.employee_id === id);
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			objectIndex = state.lazyLists.findIndex((e) => e.employee_id === id);
			objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);

			objectIndex = state.searchResults.findIndex((e) => e.employee_id === id);
			objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
		});
	},

	JOIN_BRANCH_ASSIGNMENTS(state, payload) {
		payload.forEach((e) => {
			let exists = state.lazyLists.filter((list) => list.employee_id === e.employee_id);
			!Boolean(exists.length) && state.lazyLists.push(e);
		});
	},

	SET_ACTIVE_EMPLOYEE(state, payload) {
		state.activeEmployee = payload;
	},

	SET_RESET_LAZY_LOAD(state, payload) {
		state.resetLazyLoad = payload;
	},

	SET_LISTS_TO_BE_DELETED(state, payload) {
		state.listToBeDeleted = payload;
	},

	SET_GLOBAL_TRIGGERS(state, payload) {
		state.triggers[payload.key] = payload.value;
	},
};

const actions = {
	/** GROUPED BY Employee ID */
	FETCH_BRANCH_ASSIGNMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branchAssignment',
				{
					success(response) {
						/** Commit Data */
						commit('SET_BRANCH_ASSIGNMENTS', response.data.data);
						// commit('SET_BRANCH_ASSIGNMENTS_LAZY', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	/** GROUPED BY Employee ID */
	FETCH_BRANCH_ASSIGNMENTS_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branchAssignment',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_BRANCH_ASSIGNMENTS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_BRANCH_ASSIGNMENTS_PER_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branchAssignment',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('SET_BRANCH_ASSIGNMENTS_PER_EMPLOYEE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_BRANCH_ASSIGNMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branchAssignment',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SEARCH_DATA', response.data.data);
						resolve(response.data);
					},

					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	LOOK_BRANCH_ASSIGNMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branchAssignment',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SUGGEST_DATA', response.data.data);
						resolve(response.data);
					},

					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_BRANCH_ASSIGNMENT_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branchAssignment',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_BRANCH_ASSIGNMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/branchAssignment`, payload, {
				success(response) {
					response.data.status === 'success' &&
						commit(
							response.data.data.length > 1
								? 'INSERT_MULTPLE_BRANCH_ASSIGNMENTS'
								: 'INSERT_BRANCH_ASSIGNMENTS',
							response.data.data
						);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPLOAD_BULK_BRANCH_ASSIGNMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/branchAssignment`, payload, {
				success(response) {
					if (response.data.status === 'success') {
						console.log(response.data.data);
						if (response.data.data.inserted.length) {
							commit('JOIN_BRANCH_ASSIGNMENTS', response.data.data.inserted);
						} else {
							commit('JOIN_BRANCH_ASSIGNMENTS', response.data.data);
						}
					}
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPDATE_BRANCH_ASSIGNMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/branchAssignment/${payload.id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_BRANCH_ASSIGNMENTS', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_BRANCH_ASSIGNMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/branchAssignment/${payload}`,
				{
					branchAssignments_id: payload,
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_BRANCH_ASSIGNMENTS_PER_EMPLOYEE', payload);
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},

	DELETE_BULK_BRANCH_ASSIGNMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/branchAssignment/${payload}`,
				{
					employee_id: payload,
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_BRANCH_ASSIGNMENTS', payload);
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
